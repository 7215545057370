import axios from 'axios';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { baseURL } from 'services/baseQuery';
import { Layout } from 'components/Layout/Layout';
import { ReactRouter, SafeHydrate } from 'components/Onboarding/Router';
import { layoutStyles } from 'styles/layoutStyles';

export default function RateQuote(props): React.ReactElement {
  return (
    <Layout
      fullWidth
      isLoanForm={false}
      sxWrapper={layoutStyles.admin}
      hideHeader
      removeMargin
    >
      <Head>
        <title>Advisors Mortgage Group</title>
        <meta name="description" content="Advisors Mortgage Group" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <SafeHydrate>
        <ReactRouter {...props} />
      </SafeHydrate>
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  // Fetch data from external API
  const { officerEmail } = context.query;
  let data = null;
  try {
    const res = await axios({
      baseURL: baseURL,
      url: `/officers/${officerEmail || 'amy@advisorsmortgage.com'}`
    });
    data = res.data;
  } catch (error) {
    data = null;
  }
  const rest = await serverSideTranslations(context.locale, ['common']);
  // Pass data to the page via props
  return {
    props: {
      ...rest,
      officer: data,
      httpReferrer: context.req.headers.referer
        ? context.req.headers.referer
        : null
    }
  };
};
